import React  ,{useRef, useState} from 'react';
import './Recovery.css';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import axios from 'axios';
import { Spinner, Center, Box } from '@chakra-ui/react';

const Recovery = () => {
  const contactUsRef = useRef(null);
  const [email, setEmail]= useState('');
  const [loading, setLoading]= useState(false);
  const navigate= useNavigate();
  const navigateTo=(path)=>{
    navigate(path);
  }

  const handlePasswordRecovery= async (event) =>{
    event.preventDefault();
    setLoading(true);
    try{
      const result= await axios.post('http://leatherandstyle.netlify.app/.netlify/functions/sendPasswordRecoveryEmail', {email});
      if(result.status===404){
        alert('User not found, Please Register');
        navigate('/register')
      }else if(result.status===200){
        setEmail('');
        alert('Password recovery email sent to your email. Please use the recovery link in it to recover your password.');
      }else if(result.status===500 || 535){
        alert('Internal Server Error, failed to connect to the server, sorry for the inconvience, visit again after sometime');
      }
      setLoading(false);

    }catch(e){
      console.log('error in sending the mail having password recovery link', e);
      setLoading(false);
    }
  }

  
  return (
    <div className='recovery container'>
        <Navbar contactUsRef={contactUsRef}/>
        {
          loading? (
            <Center height="100vh" background="rgba(255, 255, 255, 0.9)">
            <Box textAlign="center">
                <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
                <Box mt={4} fontSize="lg" color="gray.600">
                    Please wait processing your request...
                </Box>
            </Box>
          </Center>
          ) : (
            <>
            
            <div className="recovery-main-container">
                <div className='recovery-header'>
                    Recover Password
                </div>
                <div className='instructions'>
                    Please enter your e-mail
                </div>
                <form action="" className='recovery-form' onSubmit={handlePasswordRecovery}>
                    <input type="email" placeholder='Email Id' value={email} onChange={(e)=> setEmail(e.target.value)} className='recovery-email-input'/>
                    
                    <button className='recover-submit-button' type='submit' >
                        Recover
                    </button>
                </form>
                <div className='recover-instructions1'>
                    Remember your password? <span><button className='recovery-button' onClick={()=>navigateTo('/login')}>Back to login</button></span>
                </div>

            </div>
          </>
          )
        }
        
        <div ref={contactUsRef}>
        <ContactUs />
      </div>
    </div>
  )
}

export default Recovery;
