import React, { useRef, useState } from 'react';
import './Register.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Add axios import
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import Google from '../../assets/logos/google.png';
import { GoogleLogin } from '@react-oauth/google';
import { Spinner, Center, Box } from '@chakra-ui/react';
import { useUser } from '../../contexts/UserProvider';
const Register = () => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmailId] = useState('');
  const [pass, setPass] = useState('');
  const [phno, setPhno] = useState('');
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { fetchUserData } = useUser();
  
  const contactUsRef = useRef(null);
  const navigate = useNavigate();


  const handleGoogleRegisterSuccess = async (credentialResponse) => {
    setLoading(true)
    try {
      // Send Google credential token to the backend
      const response = await fetch('https://leatherandstyle.netlify.app/.netlify/functions/authenticate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: credentialResponse.credential }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setLoading(false)
        localStorage.setItem('token', data.token);  // Save the JWT token in localStorage
        alert('Google registration successful and logged in!');
        await fetchUserData();
        navigate('/profile');  // Redirect to profile page after login
      } else {
        setErrorMessage(data.error || 'Google registration failed');
      }
    } catch (error) {
      console.error('Google register error:', error);
      setErrorMessage('Something went wrong. Please try again.');
    }
  };
  

  // Handle registration logic
  const handleRegister = async (e) => {
    setLoading(true)
    e.preventDefault();
    
    // Prepare form data
    const formData = {
      first_name,
      last_name,
      email,
      pass,
      phno,
    };

    // Validate and submit form
    if (validateForm()) {
      try {
        const response = await fetch('https://leatherandstyle.netlify.app/.netlify/functions/register', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ first_name, last_name, email, pass, phno }),
        });
    
        const data = await response.json();
    
        if (response.ok) {
          setLoading(false);
          localStorage.setItem('token', data.token);
          alert('Registration successful, you are now logged in.');
          window.scrollTo({ top: 0});
          await fetchUserData();
          navigate('/profile', {
            state: {
              first_name: first_name,
              last_name: last_name
            }
          });
        } else {
          alert(data.error || 'Registration failed.');
          setLoading(false);
        }
      } catch (error) {
        console.error('Registration error:', error);
        alert('Something went wrong. Please try again.');
        setFirstName('');
        setLastName('');
        setEmailId('');
        setPass('');
        setPhno('');
      }
    }
  };

  // Validate email
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Validate phone number
  const validatePhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(phone);
  };

  // Validate form
  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!first_name) {
      formErrors.first_name = 'First Name is required';
      isValid = false;
    }

    if (!last_name) {
      formErrors.last_name = 'Last Name is required';
      isValid = false;
    }

    if (!email || !validateEmail(email)) {
      formErrors.email = 'Invalid email address';
      isValid = false;
    }

    if (!pass) {
      formErrors.pass = 'Password is required';
      isValid = false;
    }

    if (!phno || !validatePhone(phno)) {
      formErrors.phno = 'Invalid phone number';
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  return (
    <div className="register-container">
      <Navbar contactUsRef={contactUsRef} />
      {
        loading ? (
          <Center height="100vh" background="rgba(255, 255, 255, 0.9)">
          <Box textAlign="center">
              <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
              <Box mt={4} fontSize="lg" color="gray.600">
                  Please wait submitting your request...
              </Box>
          </Box>
     </Center>
        ) : (
          <>
             <div className="register-main-container">
        <div className="register-header">Register</div>
        {/* <div className="google-icon"> */}
          {/* <button className="google-icon-button">
            <img src={Google} alt="Google" />
            <span>Register With Google</span>
          </button> */}
          <GoogleLogin
          onSuccess={handleGoogleRegisterSuccess}
          onError={() => setErrorMessage('Google sign-in failed')}
        />
        {/* </div> */}
        <span className="register-or">OR</span>
        <div className="instructions">Please fill the below information</div>
        <form className="register-form" onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="First Name"
            className="register-firstname-input"
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          {errors.first_name && <span className="error-message">{errors.first_name}</span>}
          
          <input
            type="text"
            placeholder="Last Name"
            className="register-lastname-input"
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          {errors.last_name && <span className="error-message">{errors.last_name}</span>}
          
          <input
            type="email"
            placeholder="Email Id"
            className="register-email-input"
            value={email}
            onChange={(e) => setEmailId(e.target.value)}
            required
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
          
          <input
            type="password"
            placeholder="Password"
            className="register-pass-input"
            value={pass}
            onChange={(e) => setPass(e.target.value)} // Correctly set password state
            required
          />
          {errors.pass && <span className="error-message">{errors.pass}</span>}
          
          <input
            type="tel"
            placeholder="Phone Number"
            className="register-phno-input"
            value={phno}
            onChange={(e) => setPhno(e.target.value)}
            pattern="\d{10}"
            required
          />
          {errors.phno && <span className="error-message">{errors.phno}</span>}

          <div className="register-instructions">
            <div className="register-instruction1">
              By clicking Register, you agree to our{' '}
              <span><button className="register-ins">Terms of Service</button></span> and that you have read our{' '}
              <span><button className="register-ins">Privacy Policy</button></span>.
            </div>
            <div className="register-instruction2">
              * I hereby authorize to send notifications on SMS/ Messages/Promotional /informational messages.
            </div>
          </div>
          <button className="Register-submit-button" type="submit">Register</button>
        </form>
      </div></>
        )
      }
   
      <div ref={contactUsRef}>
        <ContactUs />
      </div>
    </div>
  );
};

export default Register;
