import React,{useState, useEffect} from 'react'
import './ProductInfo.css'
import {Box, IconButton,Input, Button, Select, FormControl, FormLabel, useToast} from '@chakra-ui/react';
import { CloseIcon, AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import ProductMgmt from '../ProductMgmt';
import axios from 'axios';



const ProductInfo = ({product, allProducts}) => {
  const mappedCatId = {
    1: 'Accessories',
    2: 'Apparels',
    3: "Men's Bags",
    4: "Travel Bags",
    5: "Women's Bags",
    6: 'Giftwares'
  };
  const toast= useToast();
  const [imageLink, setImageLink] = useState('');
  const [images, setImages] = useState(product.p_image || []);
  const [id, setId]= useState(product.p_id || '');
  const [name, setName] = useState(product.p_name || '');
  const [length, setLength]= useState(product.p_dim.split('X')[0] || '');
  const [breadth, setBreadth]= useState(product.p_dim.split('X')[1] ||'');
  const [height, setHeight]= useState(product.p_dim.split('X')[2] || '');
  const [price, setPrice]= useState(product.p_price || '');
  const [discount, setDiscount]= useState( product.p_disc || '');
  const [mainDescription, setMainDescription]= useState( product.p_desc.main || '');
  const [closureType, setClosureType ]= useState(product.p_desc.closure_type || '');
  const [material, setMaterial]= useState(product.p_desc.material || '');
  const [style, setStyle]= useState(product.p_desc.style || '');
  const [occasionType, setOccasionType] = useState(product.p_desc.occasion_type || '');
  const [category, setCategory]= useState( mappedCatId[product.cat_id]|| '');
  const [bestSeller, setBestSeller]= useState(product.best_seller===true? 'yes': 'no' || '');
  const [newArrivals, setNewArrivals]= useState(product.new_arrival===true? 'yes': 'no' || '');
  const [availableStocks, setAvailableStocks]= useState(product.p_quantity || '');
  const [back, setback]= useState(false);
  const [updateState, setUpdateSet]= useState(false);
 
  useEffect(()=>{
    if(images.length > 0){
      setUpdateSet(true);
    }else{
      setUpdateSet(false);
    }
  },[]);
  
  

  const handleImageUpload = () => {
    if (imageLink) {
      setImages([...images, imageLink]);
      setImageLink(''); 
    }
  };
  

  const handleImageDelete = (index) => {
    const updatedImages = images.filter((_, imgIndex) => imgIndex !== index);
    setImages(updatedImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0 });
  
    if (images.length === 0) {
      console.log("inside if condition");
      toast({
        title: "Please upload the images",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      const response = await axios.post(
        "https://leatherandstyle.netlify.app/.netlify/functions/addNewProduct",
        {
          id,
          name,
          images,
          length,
          breadth,
          height,
          price,
          discount,
          mainDescription,
          closureType,
          material,
          style,
          occasionType,
          category,
          bestSeller,
          newArrivals,
          availableStocks,
        }
      );
  
      if (response.status === 200) {
        toast({
          title: "Product Data Saved successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
  
        // Clear form fields only if this is a new product
        if (!updateState) {
          setId("");
          setName("");
          setImages([]);
          setLength("");
          setBreadth("");
          setHeight("");
          setPrice("");
          setDiscount("");
          setMainDescription("");
          setClosureType("");
          setMaterial("");
          setStyle("");
          setOccasionType("");
          setCategory("");
          setBestSeller("");
          setNewArrivals("");
          setAvailableStocks("");
        }
      }
    } catch (e) {
      console.log("error in submitting data to backend", e);
      toast({
        title: "Error in uploading product data",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  

  const handleSameID = (e) => {
    let id= e.target.value;
    if(allProducts){
      const isSame = allProducts.some((product) => product.p_id === id);

      if(isSame){
        setId('');
        toast({
          title: "Product id already exists, unique id required",
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }else{
        setId(id);
      }
    }else{
      setId(id);
    }
    
    
  }

  const handleSameName = (e) => {
    let name= e.target.value;
    if(allProducts){
    const isSame = allProducts.some((product) => product.p_name === name);
    if(isSame){
      setName('');
      toast({
        title: "Product name already exists, unique name required",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }else{
      setName(name);
    }
    }else{
      setName(name);
    }
    
  }

  const handleBackClick=()=>{
    setback(true);
  }
//, justifyContent: 'flex-start'
// , alignItems: 'flex-start'
  return (
    back ? (
      <ProductMgmt/>
    ) :(
    <div  className='product-info-main-container'>
      <Button leftIcon={<ArrowBackIcon/>} style={{width: '7rem', backgroundColor:'transparent'}} onClick={handleBackClick}>Back</Button>
    <div className="product-info-header">
        <span className='product-info-header-text'>Product Information</span>
    </div>
    <form onSubmit={handleSubmit}>
    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
  <FormLabel>
    <div style={{ fontSize: '1.5rem', textAlign: 'left', marginBottom: '1rem' }}>Product Images</div>
  </FormLabel>
  <div
    className="image-display-area"
    style={{
      border: '1px solid black',
      borderRadius: '20px',
      width: '75vw',
      height: '25vh',
      display: 'flex',
      overflowX: 'auto',
      overflowY: 'hidden',
      alignItems: 'center',
    }}
  >
    {images.map((imgUrl, index) => (
      <Box key={index} position="relative" display="flex" height="100%" minWidth="17rem" alignItems="center" justifyContent="center">
        {/* Image */}
        <img
          src={imgUrl}
          alt={`Product ${index}`}
          style={{
            height: '100%',
            objectFit: 'contain',
            padding: '10px',
          }}
        />

        {/* Hover Overlay */}
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="transparent"
          opacity="0"
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
          transition="opacity 0.3s ease"
          _hover={{ opacity: 1 }}
        >
          {/* Delete Button */}
          <IconButton
            aria-label="Delete image"
            icon={<CloseIcon />}
            colorScheme="whiteAlpha"
            color="black"
            size="sm"
            onClick={() => handleImageDelete(index)}
          />
        </Box>
      </Box>
    ))}
  </div>

  {/* Align Left Container */}
  <div style={{ display: 'flex', gap: '20px', marginTop: '0.5rem', width: 'fit-content' }}>
    <FormControl>
      <Input
        placeholder="Paste Img Link"
        value={imageLink}
        onChange={(e) => setImageLink(e.target.value)}
        style={{ width: '22.5vw' }}
      />
    </FormControl>
    <Button colorScheme="green" size="l" onClick={handleImageUpload} style={{ width: '8rem' }} leftIcon={<AddIcon />}>
      Upload
    </Button>
  </div>
</div>
        <div className="product-info-content-area">
          <div className="admin-product-left-area">
            <div className="ad-pd-info"> 
              <div style={{ fontSize: '1.5rem', textAlign:'left'}}>General Info</div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <FormLabel><div style={{textAlign:'left', marginTop:'1rem'}}>Product Id:</div></FormLabel>
                <FormControl isRequired ><Input placeholder='Product Id' style={{width: '100%'}} value={id} readOnly={updateState} required onChange={(e)=> handleSameID(e)}></Input></FormControl>
              </div>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
                <FormLabel><div style={{ marginRight: 'auto',textAlign:'left', marginTop:'1rem'}}>Product Name:</div></FormLabel>
                <FormControl isRequired><Input placeholder='Product Name' style={{width: '100%'}} value={name} required onChange={(e)=> handleSameName(e)}></Input></FormControl>
              </div>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
                <FormLabel><div style={{ marginRight: 'auto',textAlign:'left', marginTop:'1rem'}}>Product Dimensions:</div></FormLabel><span style={{textAlign:'left', fontSize:'0.8rem'}}>Accepts Numbers Only</span>
                <div className="prod-dim-inputs" style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'5px'}}>
                <FormControl ><Input placeholder='L' type= "number" value={length} required onChange={(e) => setLength(e.target.value)} style={{}}></Input></FormControl>
                  <div style={{fontSize: '1.2rem'}}>X</div>
                  <FormControl ><Input placeholder='B' type= "number" value={breadth} required onChange={(e) => setBreadth(e.target.value)}style={{}}></Input></FormControl>
                  <div style={{fontSize: '1.2rem'}}>X</div>
                  <FormControl ><Input placeholder='H' type= "number" value={height} required onChange={(e) => setHeight(e.target.value)} style={{}}></Input></FormControl>
                </div>
              </div>
            </div>
            
          </div>
          <div className="admin-product-right-area">
          <div className="ad-pd-info"> 
              <div style={{ fontSize: '1.5rem', textAlign:'left'}}>Pricing Info</div>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
                <FormLabel><div style={{ marginRight: 'auto',textAlign:'left', marginTop:'1rem'}}>Product Price:</div></FormLabel><span style={{textAlign:'left', fontSize:'0.8rem'}}>Accepts Numbers Only</span>
                <div style={{display:'flex', gap:'10px',alignItems:'center'}}>
                  <div style={{fontSize: '1.2rem', textAlign:'left'}}>₹</div>
                  <FormControl isRequired><Input type="number" placeholder='Product Price' value={price} required onChange={(e) => setPrice(e.target.value)} style={{width: '100%'}}></Input></FormControl>
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
              <FormLabel><div style={{ textAlign:'left', marginTop:'1rem'}}>Discount:</div></FormLabel><span style={{textAlign:'left', fontSize:'0.8rem'}}>Accepts Numbers Only</span>
              <div style={{display:'flex', gap:'10px',alignItems:'center'}}>
                  <FormControl isRequired><Input type="number" placeholder='Discount' value={discount} required onChange={(e) => setDiscount(e.target.value)} style={{width: '100%'}}></Input></FormControl>
                  <div style={{fontSize: '1.2rem', textAlign:'left'}}>%</div>
                </div>
              </div>
            </div>                                                                 
          </div>
        </div>
        <div>
          <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column', gap:'10px',padding:'20px'}}>
              <div style={{ fontSize: '1.5rem', textAlign:'left'}}>Product Description</div>
              <div style={{display:'flex', gap:'40px', flexDirection:'column'}}>
                <div style={{display:'flex', gap:'10px',flexDirection:'column', justifyContent:'flex-start'}}>
                <FormLabel><div style={{textAlign:'left'}} >Main Description:</div></FormLabel>
                <FormControl><Input placeholder='Main Description' style={{width:'100%'}} value={mainDescription}  onChange={(e) => setMainDescription(e.target.value)}></Input></FormControl>
                </div>
                <div style={{display:'flex', gap:'10px',flexDirection:'column', justifyContent:'flex-start'}}>
                <FormLabel><div style={{textAlign:'left'}}>Closure Type:</div></FormLabel>
                <FormControl><Input placeholder='Closure Type' style={{width:'100%'}}value={closureType}  onChange={(e) => setClosureType(e.target.value)}></Input></FormControl>
                </div>
                <div style={{display:'flex', gap:'10px',flexDirection:'column', justifyContent:'flex-start'}}>
                <FormLabel><div style={{textAlign:'left'}}>Material:</div></FormLabel>
                <FormControl><Input placeholder='Material' style={{width:'100%'}} value={material}  onChange={(e) => setMaterial(e.target.value)}></Input></FormControl>
                </div>
              </div>
              <div style={{display:'flex', gap:'40px', flexDirection:'column'}}>
                <div style={{display:'flex', gap:'10px',flexDirection:'column', justifyContent:'flex-start'}}>
                <FormLabel><div style={{textAlign:'left'}}>Style:</div></FormLabel>
                <FormControl><Input placeholder='Style' style={{width:'100%'}} value={style}  onChange={(e) => setStyle(e.target.value)}></Input></FormControl>
                </div>
                <div style={{display:'flex', gap:'10px',flexDirection:'column', justifyContent:'flex-start'}}>
                <FormLabel><div style={{textAlign:'left'}}>Occasion Type:</div></FormLabel>
                <FormControl><Input placeholder='Occassion Type' style={{width:'100%'}} value={occasionType}  onChange={(e) => setOccasionType(e.target.value)}></Input></FormControl>
                </div>
              </div>

          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', flexDirection: 'column' }}>
      <div style={{ fontSize: '1.5rem', textAlign: 'left' }}>Product Categorization</div>
      
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem', marginTop: '1rem', flexDirection:'column' , alignContent:'left'}}>
        
        {/* Category Selection Dropdown */}
        <div>
        <FormLabel><div style={{textAlign:'left', marginBottom:'8px'}} >Select Category</div> </FormLabel>
        <FormControl isRequired><Select placeholder="Select Category" value={category} required onChange={(e)=> setCategory(e.target.value)} style={{ width: '15rem' }}>
          <option value="Accessories">Accessories</option>
          <option value="Apparels">Apparels</option>
          <option value="Giftware">Giftware</option>
          <option value="Men's Bags">Men's Bags</option>
          <option value="Travel Bags">Travel Bags</option>
          <option value="Women's Bags">Women's Bags</option>
   
        </Select></FormControl>
        </div>
        
        {/* Best Sellers Selection */}
        <div>
        <FormLabel><div style={{textAlign:'left', marginBottom:'8px'}}>Best Sellers</div> </FormLabel>
        <FormControl isRequired><Select placeholder="Select Option" style={{ width: '15rem' }} value={bestSeller} required onChange={(e)=> setBestSeller(e.target.value)}>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Select></FormControl>
        </div>
        
               {/* New Arrivals Selection */}
               <div>
          <FormLabel><div style={{ textAlign: 'left', marginBottom: '8px' }}>New Arrivals</div></FormLabel>
          <FormControl isRequired>
            <Select placeholder="Select Option" style={{ width: '15rem' }} value={newArrivals} required onChange={(e) => setNewArrivals(e.target.value)}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormControl>
        </div>
      </div>


    </div>
  </div>
  <div style={{ fontSize: '1.5rem', textAlign: 'left' , paddingLeft:'20px', marginTop:'1rem'}}>Product Stocks</div>
  <div style={{display:'flex', flexDirection:'column', paddingLeft:'20px', marginTop:'1rem'}}>
    <FormLabel><div style={{textAlign:'left'}}>Available Stocks Count:</div></FormLabel><span style={{textAlign:'left', fontSize:'0.8rem'}}>Accepts Numbers Only</span>
    <FormControl isRequired display='flex' justifyContent='flex-start'><Input placeholder='Stocks' type='number' style={{width: '15rem'}} value={availableStocks}  onChange={(e)=> setAvailableStocks(e.target.value)}></Input></FormControl>
  </div>

  {/* Submit Button */}
  <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
    <Button colorScheme="green" type="submit" style={{ width: '10rem' }}>Save Product</Button>
  </div>
</form>
</div>
    )
  );
};

export default ProductInfo;

