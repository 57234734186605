import React, { useRef, useState } from 'react';
import './Login.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import { GoogleLogin } from '@react-oauth/google';
import { Spinner, Center, Box, ViewIcon, ViewOffIcon } from '@chakra-ui/react';
import { useUser } from '../../contexts/UserProvider';


const Login = () => {
  const location= useLocation();
  const contactUsRef = useRef(null);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [pass, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading]= useState(false);
  const { fetchUserData } = useUser();

  


  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    setErrorMessage(''); 

    try {
      const response = await fetch('https://leatherandstyle.netlify.app/.netlify/functions/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, pass }),
      });

      const data = await response.json();

      if (response.ok) {
        setLoading(false);
        console.log('login successfull')
        localStorage.setItem('token', data.token);
        window.scrollTo({ top: 0});
        alert('Logged In Successfully');
        await fetchUserData();
        if (location.state.from === '/cart') {
          console.log('location.state?.from:', location.state?.from);
          navigate('/cart'); 
        }else if(location.state?.redirectTo){
          navigate(location.state.redirectTo, {
            state: {
              productId: location.state.productId, 
            },
          });
        }else{
          navigate('/');
        }
        
      } else {
        setErrorMessage(data.error || 'Invalid email or password');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Something went wrong. Please try again.');
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    setLoading(true)
    try {
      const response = await fetch('https://leatherandstyle.netlify.app/.netlify/functions/authenticate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: credentialResponse.credential }),
      });

      const data = await response.json();

      if (response.ok) {
        setLoading(false)
        localStorage.setItem('token', data.token);  // Save the token in localStorage
        alert('Google login successful!');
        window.scrollTo({top:0});
        await fetchUserData();
        if (location.state?.from === '/cart') {
          console.log('location.state?.from:', location.state?.from);
          navigate('/cart'); 
        }else if(location.state?.redirectTo){
          navigate(location.state.redirectTo, {
            state: {
              productId: location.state.productId, 
            },
          });
        }else{
          navigate('/');
        }
      } else {
        setErrorMessage(data.error || 'Google login failed');
      }
    } catch (error) {
      console.error('Google login error:', error);
      setErrorMessage('Something went wrong. Please try again.');
    }
  };
  
  return (
    <div className='login-container'>
      <Navbar contactUsRef={contactUsRef} />
      {
        loading ? (
          <Center height="100vh" background="rgba(255, 255, 255, 0.9)">
           <Box textAlign="center">
               <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
               <Box mt={4} fontSize="lg" color="gray.600">
                   Please wait logging in...
               </Box>
           </Box>
      </Center>
        ) : (
          <>
          <div className='login-main-container'>
        <div className='login-header'>Login</div>

        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={() => console.log('Login Failed')}
        />

        {/* <span className='register-or'>OR</span> */}
        <div className='recovery-instructions1'>
          Please enter your e-mail and password
        </div>
        <form action='' className='login-form' onSubmit={handleLogin}>
          <input 
            type='email' 
            placeholder='Email Id' 
            className='login-email-input' 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input 
            type="password"
            placeholder='Password' 
            className='pass-input' 
            value={pass}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className='login-submit-button' type='submit'>
            Login
          </button>
        </form>
        {errorMessage && <div className='error-message'>{errorMessage}</div>}
        <div className='recovery-instructions1'>
          Don't have an account?{' '}
          <span>
            <button className='create-button' onClick={() => navigate('/register')}>
              Create one
            </button>
          </span>
          <div className='recovery-instructions1'>
          Forgot password?{' '}
          <span>
            <button className='create-button' onClick={() => navigate('/recovery')}>
              Recover Password
            </button>
          </span>
        </div>
        </div>
      </div>
      </>
        )
      }
      
      <div ref={contactUsRef}>
        <ContactUs />
      </div>
    </div>
  );
};


export default Login;
