import React, { useState, useRef, useEffect } from 'react';
import "./OrderSummaryPage.css";
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import { Box, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton,Spinner, Center } from '@chakra-ui/react';
import {ArrowBackIcon} from "@chakra-ui/icons"
import { useUser } from '../../contexts/UserProvider';
import { useLocation } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const { v4: uuidv4 } = require("uuid");



const OrderSummaryPage = () => {
    const key= process.env.REACT_APP_KEY;
    const [self, setSelf] = useState(null);
    const toast= useToast();
    const navigate= useNavigate();
    const location= useLocation();
    const { fetchUserData, user } = useUser();
    const contactUsRef = useRef(null);
    const [products, setProducts] = useState([]);
    const [cartSummary, setCartSummary] = useState({
      totalOriginalPrice: 0,
      totalDiscount: 0,
      totalPayable: 0,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);  
    const [loading, setLoading]= useState(false);

    // Local states for form fields
    const [first_name, setFirst_name] = useState(user?.first_name || '');
    const [last_name, setLast_name] = useState(user?.last_name || '');
    const [phno, setPhno] = useState(user?.phno || '');
    const [primary_address, setPrimary_address] = useState(user?.primary_address || '');
    const [hash, setHash]= useState('');
    const [txnid, setTxnid]= useState('');
    const [productInfo, setProductInfo]= useState('');

   
  

  

    useEffect( () => {
        // Check if the user comes from product description or cart checkout
        const fromProductDescription = location?.state;
        console.log(fromProductDescription); // Assuming product details are passed in `state` when redirected from product description page
    
        if (fromProductDescription) {
          const product = {
            id: fromProductDescription.productId,
            name: fromProductDescription.product_name,
            quantity: 1,
            originalPrice: fromProductDescription.product_price,
            stock: fromProductDescription.product_stock,
            discount: fromProductDescription.product_discount,
            discountedPrice:
            fromProductDescription.product_price -
              (fromProductDescription.product_price *
                fromProductDescription.product_discount) /
                100,
          };
          setProducts([product]);
          
        } else {
          
          const cartItems = JSON.parse(localStorage.getItem("cartData")) || [];
          console.log('cartItems', cartItems);
          setProducts(cartItems.map((item) => ({
            id: item.id,
            name: item.pname,
            quantity: item.quantity,
            originalPrice: item.cut_price,
            discountedPrice: item.price,
            discount: item.discount,
            stock: item.stock
          })));
        }
        const productinfo = products.map(product => ({
            id: product.id,
            name: product.name,
            quantity: product.quantity,
            pricePerUnit: product.originalPrice,
            discountPerUnit: product.discount, 
            total: (product.quantity * product.originalPrice) - ((product.quantity * product.originalPrice) * (product.discount / 100)) 
        }));
        
        setProductInfo(productinfo);
      }, [location]);

      useEffect(() => {
        const totalOriginalPrice = products.reduce(
          (acc, item) => acc + item.originalPrice * item.quantity,
          0
        );
        const totalDiscount = products.reduce(
          (acc, item) =>  
            acc +
            (item.originalPrice * item.quantity - item.discountedPrice* item.quantity),
          0
        );
        const totalPayable = totalOriginalPrice - totalDiscount;
    
        setCartSummary({
          totalOriginalPrice,
          totalDiscount,
          totalPayable,
        });
      }, [products]);

      const updateQuantity = (id, quantity) => {
        setProducts((prev) =>
          prev.map((product) =>
            product.id === id
              ? { ...product, quantity: Math.max(1, quantity) }
              : product
          )
        );
      };



      const handleProceedToPayment = async (event) => {
        event.preventDefault();
        
        // Validate phone number
        if (phno.length !== 10) {
          toast({
            title: "Invalid Phone Number",
            description: "Please enter a valid phone number",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          return;
        }
        
        try {
          setLoading(true);
          // Prepare the product information
          const productinfo = products.map(product => ({
            id: product.id,
            name: product.name,
            quantity: product.quantity,
            pricePerUnit: product.originalPrice,
            discountPerUnit: product.discount,
            total: (product.quantity * product.originalPrice) - ((product.quantity * product.originalPrice) * (product.discount / 100)) // Calculate total for the product
          }));
          
          // Store product info in the state
          setProductInfo(productinfo);
          // Prepare the order data to be sent to the backend
          if(!user){
            await fetchUserData();
          }
          const orderData = {
            user: {
              id: user.id,
              email: user?.email,
              first_name: user.first_name,
              last_name: user.last_name,
            },
            products: productinfo,
            shipping: {
              phone: phno,
              firstName: user.first_name,
              lastName: user.last_name,
              address: primary_address,  
            },
            paymentDetails: {
              amount: (cartSummary.totalPayable + 40).toFixed(2),
            },
          };
      
          // Step 1: Send the order data to the backend to store the order
          console.log("Sending order data to the backend...");
          const orderResponse = await axios.post("https://leatherandstyle.netlify.app/.netlify/functions/createOrder", orderData);
          setTxnid(orderResponse.data.txnid);
      
          if (orderResponse.data.success) {
            console.log("Order stored successfully, proceeding to generate hash...");
      
            // Step 2: Once the order is stored, call the generate hash API
            const paymentData = {
              productinfo: productinfo,
              amount: (cartSummary.totalPayable + 40).toFixed(2),
              email: user.email,
              firstname: user.first_name,
              lastname: user.last_name,
              phone: phno,
              txnid: orderResponse.data.txnid
            };
            
            const hashResponse = await axios.post("https://leatherandstyle.netlify.app/.netlify/functions/generateHash", paymentData);
            if(hashResponse.status===200){
              setLoading(false);
              const hash = hashResponse.data.hash;
              setHash(hash);
              setSelf(hashResponse.data);
              setIsModalOpen(true);
            }
            
          } else {
            toast({
              title: "Order Error",
              description: "Failed to store the order. Please try again.",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        } catch (err) {
          console.error(err);
          toast({
            title: "Payment Error",
            description: "Failed to initiate payment. Please try again.",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      };
      

    
    return (
        <div className='main-ordersummary-page-container'>
            <Navbar contactUsRef={contactUsRef} />
            {
              loading ? (
                <Center height="100vh" background="rgba(255, 255, 255, 0.9)">
                  <Box textAlign="center">
                      <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
                      <Box mt={4} fontSize="lg" color="gray.600">
                          Please wait processing your order...
                          Do not refresh the page.
                      </Box>
                  </Box>
              </Center>
              ): (
                <>
                <Button
                colorScheme='transparent'
                color='back'
                leftIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
                className='os-back-button' 
                >
                    Back
                </Button>
            <div  className='os-note'>Note: Do not refresh this page</div>
            <div className='ordersummary-page-area'>
               
                <Box className='ordersummary-area'>
                <form action="">
                    <div className='ordersummary-area-header'>Order Summary</div>
                    <div className='ordersummary-area-body'>
                        <div className='ordersummary-area-body-left'>
                            <div className='os-area-body-header' style={{fontWeight:'bold'}}>Delivery Details</div>
                            <div className="os-area-body">
                                <div  className="os-area-form">
                                    {/* Name Fields */}
                                    <div className="os-area-form-field">
                                        <div  className='form-navgav'>
                                            <span className='deliver-to-text'>Deliver To:</span>
                                            <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                                                {!first_name && (
                                                <div className="error-message">
                                                    * Please add your first name.
                                                </div>
                                                )}
                                                <div style={{display:'flex', alignItems:'center'}}>
                                                    <span style={{textAlign:'left'}}>First Name:</span>
                                                    <input
                                                        required
                                                        type="text"
                                                        name="name"
                                                        placeholder="First Name"
                                                        value={first_name}
                                                        // readOnly={!!user?.first_name}
                                                        onChange={(e) => setFirst_name(e.target.value)}
                                                        style={{width:'20%', marginRight:'0.5rem'}}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                                                {!last_name && (
                                                <div className="error-message">
                                                    * Please add your last name.
                                                </div>
                                                )}
                                                <div style={{display:'flex', alignItems:'center'}}>
                                                    <span >Last Name:</span>
                                                    <input
                                                        required
                                                        type="text"
                                                        name="last_name"
                                                        placeholder="Last Name"
                                                        value={last_name}
                                                        // readOnly={!!user?.last_name}
                                                        onChange={(e) => setLast_name(e.target.value)}
                                                        style={{width:'20%'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                 
                                    </div>

                                    {/* Phone Number Field */}
                                    {!phno && (
                                        <div className="error-message">
                                            * Please add your phone number.
                                        </div>
                                    )}
                                    <div className="os-area-form-field">
                                        <span>Phone Number:</span>
                                        <input
                                            required
                                            type="number"
                                            name="phone"
                                            placeholder="Phone Number (+91)"
                                            value={phno}
                                            // readOnly={!!user?.phno}
                                            onChange={(e) => setPhno(e.target.value)}
                                        />
                                    </div>

                                    {/* Address Field */}
                                    {!primary_address && (
                                        <div className="error-message">
                                            * Please add your delivery address.
                                        </div>
                                    )}
                                    <div className="os-area-form-field">
                                            <span style={{textAlign:'left'}}>Delivery Address:</span>
                                            <textarea
                                                required
                                                type="text"
                                                name="address"
                                                placeholder="Full Residetial Address (house no, road no, apt name, sector, town, city, state, pin)"
                                                value={primary_address}
                                                // readOnly={!!user?.primary_address}
                                                onChange={(e) => setPrimary_address(e.target.value)}
                                                style={{border:'1px solid #ccc', borderRadius:'4px', width:'80%', paddingLeft: '10px', textAlign:'center'}}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='ordersummary-area-body-right'>
                        <div className='os-area-body-header' style={{fontWeight:'bold'}}>Products Summary</div>
                            <div className="products-container">
                                {products.map((product) => (
                                <div key={product.id} className="product-item">
                                    <div className="product-name">Product Name: {product.name}</div>
                                    <div className="product-quantity">
                                        Quantity:
                                        <div className="quantity-controls">
                                            {/* Decrease Button */}
                                            <button
                                                className="quantity-btn"
                                                onClick={(e) => { e.preventDefault(); updateQuantity(product.id, Math.max(1, product.quantity - 1))}}
                                            >
                                                -
                                            </button>

                                            {/* Quantity Input */}
                                            <input
                                                type="number"
                                                value={product.quantity}
                                                min="1"
                                                onChange={(e) => updateQuantity(product.id, +e.target.value)}
                                            />

                                            {/* Increase Button */}
                                            <button
                                                className="quantity-btn1"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (product.quantity < product.stock) {
                                                        updateQuantity(product.id, product.quantity + 1);
                                                    }
                                                }}
                                            >
                                                +
                                            </button>
                                        </div>

                                        {/* Stock Warning */}
                                        {product.quantity >= product.stock && (
                                            <div className="stock-warning">
                                                Stock limit reached
                                            </div>
                                        )}
                                    </div>
                                    <div className="product-prices">
                                    <span>Original Price: ₹ {product.originalPrice} /-</span>
                                    <span>Discount: {product.discount}%</span>
                                    <span>Discounted Price: ₹ {product.discountedPrice} /-</span>
                                    </div>
                                </div>
                                ))}
                            </div>
                            <div className="summary-section">
                                <div style={{textAlign:'center', fontSize:'1.2rem'}}>Total</div>
                                <div>Total Price: ₹ {cartSummary.totalOriginalPrice} /-</div>
                                <div>Total Discount: - ₹ {cartSummary.totalDiscount} /-</div>
                                <div>Shipping Charges: ₹ 40 /-</div>
                                <div>Total Payable: ₹ {cartSummary.totalPayable + 40} /-</div>
                            </div>
                        </div>
                    </div>
                    <button className='proceed-to-payment-button' type='button' onClick={handleProceedToPayment}>Confirm and Proceed to Payment</button>
                    </form>
                </Box>
                
            </div>
            
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay />
                <ModalContent maxHeight='80vh' overflowY='auto'>
                    <ModalHeader>Order Summary</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className="modal-delivery-details">
                            <h3 style={{fontWeight:'bold'}}>Delivery Details</h3>
                            <p><strong>Deliver To:</strong> {first_name} {last_name}</p>
                            <p><strong>Phone:</strong> {phno}</p>
                            <p><strong>Address:</strong> {primary_address}</p>
                        </div>
                        <div className="modal-order-summary">
                            <h3 style={{fontWeight:'bold', marginTop: '1rem'}}>Order Summary</h3>
                            {products.map((product) => (
                                <div key={product.id}>
                                    <div>Product Name: {product.name}</div>
                                    <div>Product Quantity: {product.quantity}</div>
                                    <div>Product Original Price:₹ {product.originalPrice} /-</div>
                                    <div>Product Discounted Price:₹ {product.discountedPrice} /-</div>
                                    <div style={{height:'20px'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className='modal-total-summary'>
                            <h3  style={{fontWeight:'bold', marginTop: '1rem'}}>Total:</h3>
                            <div>Total Price: ₹ {cartSummary.totalOriginalPrice} /-</div>
                            <div>Discount: -₹ {cartSummary.totalDiscount} /-</div>
                            <div>Shipping Charges: - ₹ 40 /-</div>
                            <div>Total Payable Amt: ₹{cartSummary.totalPayable + 40} /-</div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <form action="https://secure.payu.in/_payment" method="post">
                            <input type="hidden" name="key" value={key} />
                            <input type="hidden" name="txnid" value={txnid} />
                            <input type="hidden" name="productinfo" value={productInfo} />
                            <input type="hidden" name="amount" value={(cartSummary.totalPayable + 40).toFixed(2)} />
                            <input type="hidden" name="email" value={user?.email} />
                            <input type="hidden" name="firstname" value={user?.first_name} />
                            <input type="hidden" name="lastname" value={user?.last_name} />
                            <input type="hidden" name="surl" value="https://leatherandstyle.netlify.app/.netlify/functions/paymentSuccess" />
                            <input type="hidden" name="furl" value="https://leatherandstyle.netlify.app/.netlify/functions/paymentFailure" />
                            <input type="hidden" name="phone" value={phno} />
                            <input type="hidden" name="udf1" value={9967} />
                            <input type="hidden" name="udf2" value={2963} />
                            <input type="hidden" name="udf3" value={8599} />
                            <input type="hidden" name="udf4" value={8789} />
                            <input type="hidden" name="udf5" value={8988} />
                            <input type="hidden" name="hash" value={hash} />
                            <Button colorScheme="blue" type="submit">Pay Now</Button>
                            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Close</Button>
                        </form>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            </>

              )
            }
            <div ref={contactUsRef} style={{marginTop: '6rem'}}>
                <ContactUs />
            </div>
                
        </div>
    );
};

export default OrderSummaryPage;
