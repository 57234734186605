import React, { useState, useEffect, useRef } from 'react';
import { Box, Input, Button, Table, Thead, Tbody, Tr, Th, Td, TableContainer, useToast,   AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import './AdminPage.css';
import ProductInfo from './ProductInfo/ProductInfo';

const ProductMgmt = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");
  const [showProductInfo, setShowProductInfo] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); // To store selected product for update
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const cancelRef = useRef();

  const handleOpenDialog = (productId) => {
      

        setIsOpen(true);
   
      setProductToDelete(productId);
  };
  

  const handleCloseDialog = () => {
    setIsOpen(false);
    setProductToDelete(null);
  };

  const handleDeleteProduct= async (productId)=>{
    try{
          console.log("User confirmed!");
          const response= await fetch(`https://leatherandstyle.netlify.app/.netlify/functions/deleteproduct`, {
            method:'DELETE',
            headers:{
              'Content-Type':'application/json',
            },
            body: JSON.stringify({p_id: productId}),
          });
          const result = await response.json();
    
          if (response.status === 200 && result.success) {
            setData(data.filter((item) => item.p_id !== productId)); 
            setFilteredProducts(filteredProducts.filter((item) => item.p_id !== productId)); 
            toast({
              title: "Product Deleted Successfully",
              description: result.message,
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Error Deleting Product",
              description: result.message || "Something went wrong",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
      
    }catch(e){
      console.log('error in sending delete request to database', e);
      toast({
        title: "Error Deleting Product",
        description: "Something went wrong",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }

  const handleConfirmDelete = async () => {
    handleCloseDialog();
    await handleDeleteProduct(productToDelete);
  };

  const scrollToTop= ()=>{
    window.scrollTo({top:0});
  }

  // Fetch products from the backend
  const fetchProduct = async () => {
    try {
      const response = await fetch('https://leatherandstyle.netlify.app/.netlify/functions/getAllProducts');
      const products = await response.json();
      if (products) {
        setLoading(false);
        setData(products);
        setFilteredProducts(products); 
      } else {
        setError('No Products Available');
      }
    } catch (e) {
      console.log('Error fetching products:', e);
      setError('Failed to fetch product details');
      setLoading(false);
    }
  };



  const handleUpdateProduct = (product) => {
    setSelectedProduct(product); 
    scrollToTop();
    setShowProductInfo(true); 
  };

  useEffect(() => {
    fetchProduct();
  }, []);


  const handleSearch = () => {
    if (searchQuery.trim() === "") {
  
      setFilteredProducts(data);
    } else {
      const lowercasedQuery = searchQuery.trim().toLowerCase();
      const results = data.filter((product) =>
        product.p_id.toLowerCase().includes(lowercasedQuery) ||
        product.p_name.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredProducts(results);
    }
  };

  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

 
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.trim() === "") {
      setFilteredProducts(data); 
    }
  };

  const handleAddProductClick = () => {
    scrollToTop();
    setSelectedProduct({
      'p_image':[],
      'p_id':'',
      'p_name':'',
      'p_dim':'',
      'p_price':'',
      'p_disc':'',
      'p_quantity':'',
      'p_desc':{
        'main':'',
        'closure_type':'',
        'material':'',
        'style':'',
        'occasion_type':''
      },
      'cat_id':'',
      'best_seller':'',
      'new_arrival':'',
    });
    setShowProductInfo(true); 
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
    {
      showProductInfo ? (
        <ProductInfo product={selectedProduct} allProducts= {data}/>
      ) : (
        <Box className='product-mgmt-main-container'>
          <div className='product-mgmt-header' style={{textAlign:"left", paddingLeft:'20px'}}>Product Management</div>
          <Box className='search-bar-container' display="flex" alignItems="center">
            <Input
              className='product-search-bar'
              placeholder="Search Product Id or Name"
              value={searchQuery}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            <Button onClick={handleSearch} colorScheme="blue" size="sm" leftIcon={<SearchIcon />} ml="2">
              Search
            </Button>
          </Box>

          <Box display='flex' justifyContent='flex-end' mb='2rem'>
            <Button colorScheme="green" size="sm" leftIcon={<AddIcon />} onClick={handleAddProductClick}>Add New Product</Button>
          </Box>

          <Box className='product-table'>
            <TableContainer >
              <Table variant="simple" size="sm" >
                <Thead>
                  <Tr>
                    <Th textAlign="center">Sr. No.</Th>
                    <Th textAlign="center">Product Id</Th>
                    <Th textAlign="center">Product Name</Th>
                    <Th textAlign="center">Price</Th>
                    <Th textAlign="center">Discount</Th>
                    <Th textAlign="center">Available Stock</Th>
                    <Th textAlign="center">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredProducts.map((item, index) => (
                    <Tr key={item.id}>
                      <Td textAlign="center">{index + 1}</Td>
                      <Td textAlign="center">{item.p_id}</Td>
                      <Td textAlign="center">{item.p_name}</Td>
                      <Td textAlign="center">₹ {item.p_price.toFixed(2)}</Td>
                      <Td textAlign="center">{item.p_disc.toFixed(2)} %</Td>
                      <Td textAlign="center">{item.p_quantity}</Td>
                      <Td textAlign="center">
                      <Button 
                          colorScheme="blue" 
                          size="sm" 
                          mr="2" 
                          onClick={() => handleUpdateProduct(item)}
                        >
                          Update
                        </Button>
                        <Button 
                          colorScheme="red" 
                          size="sm" 
                          onClick={() => handleOpenDialog(item.p_id)}
                        >
                          Delete
                        </Button>
                        <AlertDialog
                          isOpen={isOpen}
                          leastDestructiveRef={cancelRef}
                          onClose={handleCloseDialog}
                        >
                          <AlertDialogOverlay>
                            <AlertDialogContent>
                              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Delete Product
                              </AlertDialogHeader>
                              <AlertDialogBody>
                                Are you sure? You can't undo this action afterwards.
                              </AlertDialogBody>
                              <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={handleCloseDialog}>
                                  Cancel
                                </Button>
                                <Button
                                  colorScheme="red"
                                  onClick={handleConfirmDelete}
                                  ml={3}
                                >
                                  Delete
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialogOverlay>
                        </AlertDialog>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

      )
    }
    </div>
  )
    

};

export default ProductMgmt;
