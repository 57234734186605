import React, {useEffect, useRef, useState} from 'react';
import './BestSellers.css';
import { useNavigate } from 'react-router-dom';
import { Spinner, Center, Box } from '@chakra-ui/react';

const BestSellers = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const touchStartX = useRef(0);
    const [isLaptopScreen, setIsLaptopScreen] = useState(window.innerWidth >= 768);
    const navigate = useNavigate();
    const [bestSeller, setBestSeller]= useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
      const fetchProducts= async ()=>{
        try {
          const result = await fetch(`https://leatherandstyle.netlify.app/.netlify/functions/getBestSellers`);
          const data = await result.json();
          setBestSeller(data);
      } catch (error) {
          console.error('Error fetching best sellers:', error);
      } finally {
          setLoading(false); 
      }
      }
      fetchProducts();
    }, []);

    const itemsPerView = isLaptopScreen ? 3 : 1;

    const handleProductClick= (p_id)=>{
      window.scrollTo({top:0});
      navigate('/products',{
        state: {productId: p_id}
      }
      )
    }

  const nextSlide = () => {
    
      setCurrentIndex((prevIndex) => 
          Math.min(prevIndex + 1, bestSeller.length - itemsPerView)
      );
  };

  const prevSlide = () => {
     
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleTouchStart = (e) => {
      touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
      const touchEndX = e.changedTouches[0].clientX;
      const difference = touchStartX.current - touchEndX;
      if (difference > 50) {
          nextSlide();
      } else if (difference < -50) {
          prevSlide();
      }
  };

  useEffect(() => {
      const handleResize = () => {
          setIsLaptopScreen(window.innerWidth >= 768);
      };
      window.addEventListener('resize', handleResize);

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);
  //
  return (
    <div className="main-area">
    {loading ? (
         <Center height="55vh" background="rgba(255, 255, 255, 0.9)">
          <Box textAlign="center">
              <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
              <Box mt={4} fontSize="lg" color="gray.600">
                  Loading Best Sellers...
              </Box>
          </Box>
     </Center>
    ) : (
        <>
            <div className="title-group">
                <div className="line11"></div>
                <div className="title">Best Sellers</div>
                <div className="line22"></div>
            </div>
            <div className="blur-bg">
                {!isLaptopScreen && <div className="instr">Swipe to see the products</div>}

                {currentIndex > 0 && (
                    <button className="carousel-button-prev" onClick={prevSlide}>
                        &#10094;
                    </button>
                )}
                <div className="carousel-container-1">
                    <div className="carousel-wrapper1">
                        <div
                            className="carousel-slide"
                            style={{
                                transform: `translateX(-${currentIndex * (100 / itemsPerView)}%)`
                            }}
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd}
                        >
                            {bestSeller.map((product, index) => (
                                <div
                                    key={index}
                                    className="carousel-item"
                                    onClick={() => handleProductClick(product.p_id)}
                                >
                                    <img
                                        src={product.p_image[0]}
                                        alt={product.p_name}
                                        className="product-photo"
                                    />
                                    <div className="product-title1">
                                        <div className="product-name">{product.p_name}</div>
                                        <div>
                                            <span className="product-info-details">
                                                ₹
                                                {product.p_price -
                                                    (product.p_disc / 100) * product.p_price}{' '}
                                                <span className="cut-price">₹{product.p_price}</span>/-
                                                <span className="diff-disc">30%OFF</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {currentIndex < bestSeller.length - itemsPerView && (
                    <button className="carousel-button-next" onClick={nextSlide}>
                        &#10095;
                    </button>
                )}
            </div>
        </>
    )}
</div>
  );
}

export default BestSellers;
