import React, {useEffect} from 'react';
import './Shop.css';
import CloseIcon from '../../assets/icons/crosswhite.png';
import { useNavigate } from 'react-router-dom';

const Shop = ({ isOpen, onClose }) => {
  const navigate= useNavigate();
    const navigateTo=(cat_id)=>{
      onClose();
      window.scrollTo({ top: 0});
        navigate('/full-shop',
          {
            state: {cat_id}
          }
        );
    }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest('.shop-content') === null) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className={`shop ${isOpen ? 'open' : ''}`}>
      <div className="shop-content">
        <button className="close-button" onClick={onClose}>
          <img src={CloseIcon} alt="Close" />
        </button>
        <h2>Shop Categories</h2>
        <ul>
          <li onClick={()=>navigateTo(1)}>Accessories</li>
          <li onClick={()=>navigateTo(4)}>Mens's Bags</li>
          <li onClick={()=>navigateTo(6)}>Women's Bags</li>
          <li onClick={()=>navigateTo(2)}>Apparels</li>
          <li onClick={()=>navigateTo(5)}>Travel Bags</li>
          <li onClick={()=>navigateTo(3)}>Giftwares</li>
          {/* Add more categories as needed */}
        </ul>
      </div>
    </div>
  );
};

export default Shop;