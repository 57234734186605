import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { formatPrice } from './PriceTag'; // Import formatPrice function
import './cart.css';
import { useCart } from '../../pages/ProductDescription/UseCart';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const OrderSummaryItem = (props) => {
  const { label, value, children } = props;
  return (
    <Flex justify="space-between" fontSize="sm" className='cart-product-data'>
      <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  );
};

export const CartOrderSummary = () => {
  const { getCartTotal, cartData } = useCart(); // Get cartData from useCart
  const total = getCartTotal();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleCheckout = () => {
    window.scrollTo({ top: 0 });
    const token= localStorage.getItem('token')
    if(!token){
      alert('Please login to continue');
      window.scrollTo({top:0});
      navigate('/login',{
        state: { from: '/cart' }
      });
    }else{
      navigate('/orderSummary')
    }
    
    
  };

  return (
    <Stack spacing="8" borderWidth="1px" rounded="lg" padding="8" width="full" sx={{ fontFamily: `'Gowun Batang', serif` }}>
      <Heading size="md" sx={{ fontFamily: `'Gowun Batang', serif` }}>
        Order Summary
      </Heading>
      <Stack spacing="6">
        <OrderSummaryItem label="Subtotal" value={formatPrice(total)} />
        <OrderSummaryItem label="Shipping + Tax">
          Rs. 40/-
        </OrderSummaryItem>
        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">Total</Text>
          <Text fontSize="xl" fontWeight="extrabold">{formatPrice(total + 40)}</Text> {/* Add shipping */}
        </Flex>
      </Stack>
      <Button
        colorScheme='blue'
        size="lg"
        fontSize="md"
        rightIcon={<FaArrowRight />}
        className='checkout-button'
        sx={{ backgroundColor: 'rgba(50, 0, 1, 0.9)', color: 'white' }}
        _hover={{ backgroundColor: 'rgba(50, 0, 1, 1)' }}
        onClick={handleCheckout} // Corrected to pass the function reference
      >
        Checkout
      </Button>
    </Stack>
  );
};
