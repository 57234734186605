import React, { useState, useEffect, useRef } from 'react';
import './ProductDescription.css';
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import { useLocation, useParams } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useCart } from './UseCart';
import { Spinner, Center, Box } from '@chakra-ui/react';


const ProductDescription = () => {
  const contactUsRef = useRef(null);
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(0);
  const location = useLocation();
  const {productId} = location.state ||  {};
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const { addToCart } = useCart();
  const toast = useToast();

  const handleAddToCart = () => {
    if (product) {
     
      addToCart({
        id: product.p_id,
        pname: product.p_name,
        price: product.p_price-((product.p_disc/100)*product.p_price),
        cut_price: product.p_price,
        mainImage: product.p_image[0], 
        stock: product.p_quantity,
        discount: product.p_disc
        // category: category,
      });
    } else {
      toast({
        title: 'Product not found!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  const handleBuyNowClick = () => {
    const token= localStorage.getItem('token')
    console.log('token:', token);
    if(!token){
      alert('Please login to continue');
      window.scrollTo({top:0});
      navigate('/login',{
        state: {
          redirectTo:'/products',
          productId:product.p_id,
        }
      }
      )
    }else{
      window.scrollTo({top:0});
      navigate('/orderSummary',{
        state: {
          productId:product.p_id,
          product_name: product.p_name,
          product_price: product.p_price,
          product_quantity: 1,
          product_discount: product.p_disc,
          product_stock: product.p_quantity,
        }
      }
      );
    }

  };
  const fetchProduct = async () => {
    try {
      const response = await fetch(`https://leatherandstyle.netlify.app/.netlify/functions/getProductDetailsById?productId=${productId}`);
      const data = await response.json();
      console.log("Fetched Data:", data);
      if (data) {
        setProduct(data);
      } else {
        setError('Product not found');
      }
      setLoading(false);
    } catch (err) {
      console.error("Fetch Error:", err);
      setError('Failed to fetch product details');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchProduct();
    }
    console.log('productId:', productId);
  }, [productId]);

  if (loading) return <div>
    <Center height="100vh" background="rgba(255, 255, 255, 0.9)">
      <Box textAlign="center">
          <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
          <Box mt={4} fontSize="lg" color="gray.600">
              Please wait loading the product...
          </Box>
      </Box>
    </Center>
</div>;
  if (error) return <div>{error}</div>;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % product.p_image.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + product.p_image.length) % product.p_image.length);
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const difference = touchStartX.current - touchEndX;

    if (difference > 50) {
      nextSlide();
    } else if (difference < -50) {
      prevSlide();
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3)); 
  };
  
  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1)); 
  };

  return (
    <div className='pd-container'>
      <Navbar contactUsRef={contactUsRef}/>
      <div className="pd-main-container">
        <div className="product-header">
          {product.p_name}
        </div>
        <div className="pd-carousel-area">
          <div className="carousel-wrapper2">
            <button className='carousel-button prev' onClick={prevSlide}>&#10094;</button>
            <div
              className='carousel-slide2'
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              {product.p_image.map((image, index) => (
                <div key={index} className='carousel-item2' onClick={openModal}>
                  <img src={image} alt={`Product View ${index + 1}`} className='product-photo2' />
                </div>
              ))}
            </div>
            <button className='carousel-button next' onClick={nextSlide}>&#10095;</button>
          </div>
        </div>
        {/* Modal for Zoomed Images */}
      {showModal && (
        
        <div className="modal-overlay" >
          <button className="close-button" onClick={closeModal}>&times;</button>
          {/* <div className="zoom-controls">
            <button className="zoom-button" onClick={handleZoomIn}>Zoom In</button>
            <button className="zoom-button" onClick={handleZoomOut}>Zoom Out</button>
          </div> */}
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            
            <div className="carousel-wrapper-modal">
              <button className='carousel-button1 prev' onClick={prevSlide}>&#10094;</button>
              <div
                className='carousel-slide-modal'
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
              >
                {product.p_image.map((image, index) => (
                  <div key={index} className='carousel-item-modal'>
                    <img
                      src={image}
                      alt={`Product View ${index + 1}`}
                      className='product-photo-modal'
                      style={{
                        transform: `scale(${zoomLevel}) translate(${(1 - zoomLevel) * 50}%, ${(1 - zoomLevel) * 50}%)`,
                      }} 
                    />
                  </div>
                ))}
              </div>
              <button className='carousel-button1 next' onClick={nextSlide}>&#10095;</button>
            </div>
          </div>
        </div>
      )}
        <div className='product-name-cost'>
          <div className='product-name1'>{product.p_name}</div>
          <span className='prices1'>₹{product.p_price-((product.p_disc/100)*product.p_price)} MRP <span className='cut-price1'>Rs{product.p_price}/-</span> <span className='diff-disc1'>{product.p_disc}% OFF</span></span>
          <div className='instr-pd'>*Inclusive of all taxes</div>
        </div>
        <div className="pd-description">
          <div className='d-header'>Product Information: </div>
          {product.p_desc.main && (
            <div className="small-header">Description: {product.p_desc.main}</div>
          )}
          {product.p_desc.closure_type && (
            <div className="small-header">Closure Type: {product.p_desc.closure_type}</div>
          )}
          {product.p_desc.material && (
            <div className="small-header">Material: {product.p_desc.material}</div>
          )}
          {product.p_desc.style && (
            <div className="small-header">Style: {product.p_desc.style}</div>
          )}
          {product.p_desc.ocassion_type && (
            <div className="small-header">Ocassion Type: {product.p_desc.ocassion_type}</div>
          )}
        </div>
        <div className="pd-dimension">
          <div className='d-header'>Dimensions:</div>
          [lxbxh]
          <div></div>
            {product.p_dim} inch
        </div>
        <div className="pd-care-info">
        <div className='d-header'>Care Instructions:</div>
            <div></div>1. Clean Regularly: Dust with a soft cloth and spot clean with a damp cloth. Use leather cleaner for tough stains.
            <div></div>2. Condition Periodically: Apply leather conditioner every 6-12 months to keep it supple.
            <div></div>3. Protect from Water: Pat dry if wet, and consider using a waterproofing spray.
            <div></div>4. Store Properly: Store in a cool, dry place; use dust bags and avoid folding.
            <div></div>5. Handle with Care: Clean hands before handling.
        </div>
        {product.p_quantity !== 0 && (
          <>
            <div>
              <button className="buy-now-button" onClick={handleAddToCart}>Add To Cart</button>
            </div>
            <div>
              <button className="buy-now-button" onClick={handleBuyNowClick}>Buy Now</button>
            </div>
          </>
        )}
        {product.p_quantity === 0 && (
          <>
            <div style={{marginTop:'1rem', marginBottom:'1rem', fontWeight:'bold', fontSize:'1.5rem', color:' rgba(50, 0, 1, 0.9)'}}>
              Currently the Product is out of stock, please visit after some time.
            </div>
          </>
        )}

        
        <div className='pd-Return-Policy'>
      <div className='d-header' onClick={toggleContent}>
        <div>Return Policy</div>
        <div className='plus-icon'>
          {isOpen ? '-' : '+'}
        </div>
      </div>
      {isOpen && (
        <>
          <div className='rp-des'>
            At Leather and Style, we want you to be completely satisfied with your purchase. If you are not
            satisfied with your product, you may return it within 14 days of the delivery date.
          </div>
          <a href="https://drive.google.com/file/d/1eNZD3bZXwyAqxqMazEKv_PZ62WHE7rYM/view?usp=sharing" className='link-rp'>
            More info
          </a>
        </>
      )}
    </div>
    </div>
    <div ref={contactUsRef}>
        <ContactUs />
      </div>
    </div>
  );
};

export default ProductDescription;



