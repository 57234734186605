import React, { useState } from 'react';
import { Box, Input, Stack, InputGroup, InputRightElement, Icon } from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './AdminLogin.css';
import logo from '../../assets/logos/newlogonobg.png';

const AdminLogin = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <div className='main-admin-login-container'>
      <Box bg="bg" className='admin-title'>
        <img src={logo} alt="Logo" className="admin-logo" />
        <span className='admin-title-text'>Leather and Style</span>
      </Box>
      <Box bg="bg" shadow="md" borderRadius="md" className='admin-login-box'>
        <span className='admin-login-box-header'>Admin Login</span>
        <form>
        <Stack className='admin-login-inputs' >
          <Input placeholder="Email" type="email" />
          <InputGroup>
            <Input
              placeholder="Password"
              type={showPassword ? "text" : "password"}
            />
            <InputRightElement width="3rem" marginTop="10px">
              <Icon
                as={showPassword ? FaEyeSlash : FaEye}
                onClick={togglePasswordVisibility}
                cursor="pointer"
                color="gray.500"
              />
            </InputRightElement>
          </InputGroup>
        </Stack>
          <button type='submit' className='admin-login-submit'>
            Login
          </button>
        </form>
      </Box>
      
    </div>
  );
}

export default AdminLogin;
