import React from "react";
import { Button, Box, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const PaymentFailure = () => {
  const navigate= useNavigate();
  return (
    <Box textAlign="center" p={5}>
      <Text fontSize="2xl" fontWeight="bold" color="red.500">
        Payment Failed!
      </Text>
      <Text fontSize="l" fontWeight="bold" color="red.500">
        Please try again after 15-20 minutes.
      </Text>
      <Text mt={4}>Something went wrong with your payment.</Text>
      <Button mt={5} colorScheme="red" onClick={()=> {navigate('/')}}>
        Go Back to Home page
      </Button>
    </Box>
  );
};

export default PaymentFailure;
