import React, { createContext, useContext, useState, useEffect } from 'react';
import { Spinner, Center, Box } from '@chakra-ui/react';


const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoading(false);
        return;
      }

      setLoading(true);
      const response = await fetch('https://leatherandstyle.netlify.app/.netlify/functions/getUser', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUser({
          ...data,
          token,
        });
        console.log('userdata:', data);
      } else if (response.status === 401) {
        console.error('JWT expired or invalid');
        alert('You have been logged out as your login session has expired.');
        localStorage.removeItem('token');
        setUser(null);
      } else {
        console.error('Error fetching user data:', response.statusText);
        alert('Error fetching user data');
        setUser(null);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, fetchUserData, loading }}>
      {loading ? (
        <Center height="100vh" background="rgba(255, 255, 255, 0.9)">
          <Box textAlign="center">
            <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
            <Box mt={4} fontSize="lg" color="gray.600">
              Please wait, loading user data...
            </Box>
          </Box>
        </Center>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
