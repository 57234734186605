import React, { useEffect, useState } from "react";
import { Button, Box, Text } from "@chakra-ui/react";
import InvoiceDownload from "./InvoiceDownload"; // Import the InvoiceDownload component
import './PaymentSuccess.css';

const PaymentSuccess = () => {
  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
    // Extract data from URL
    const urlParams = new URLSearchParams(window.location.search);
  
    const orderId = urlParams.get("orderId");
    const mihpayid = urlParams.get("mihpayid");
    const products = JSON.parse(decodeURIComponent(urlParams.get("products")));
    const shippingDetails = JSON.parse(decodeURIComponent(urlParams.get("shippingDetails")));
    const updatedAt = decodeURIComponent(urlParams.get("updatedAt"));

    // Calculate total amount, discount, and payable amount
    const totalAmount = products.reduce((sum, product) => sum + (product.price || 0) * (product.quantity || 0), 0);
    const totalDiscount = products.reduce((sum, product) => sum + ((product.discount || 0) / 100) * (product.price || 0) * (product.quantity || 0), 0);
    const totalPayable = totalAmount - totalDiscount + 40;
  
    // Set the data for the invoice
    setInvoiceData({
      orderId,
      mihpayid,
      updatedAt,
      shippingDetails,
      products,
      totalAmount,
      totalDiscount,
      totalPayable,
    });
  }, []);

  if (!invoiceData) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      className="payment-success-container"
    >
      <Text fontSize="2xl" fontWeight="bold" color="green.500">
        Congratulations 🥳
      </Text>
      <Text fontSize="2xl" fontWeight="bold" color="green.500">
        Payment Successful!
      </Text>
      <Text mt={4}>Transaction ID: {invoiceData.mihpayid}</Text>
      <Text>Order Number: {invoiceData.orderId}</Text>
      <Text style={{ marginTop: "1rem" }}>Order will be delivered to you via qouries in 7-8 business days!</Text>
      <Text style={{ marginTop: "0.4rem" }}>Thank you. Visit Again...</Text>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          marginTop: "1rem",
        }}
      >
        <InvoiceDownload invoiceData={invoiceData} />
        <Button variant="outline" onClick={() => (window.location.href = "/")}>
          Go Back to Home
        </Button>
      </div>
    </Box>
  );
};

export default PaymentSuccess;
