'use client'
import './AdminPage.css';

import React, {useState} from 'react'
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
} from '@chakra-ui/react'
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiMenu,
} from 'react-icons/fi'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { RiArrowRightLine, RiMailLine } from "react-icons/ri"
import Dashboard from './Dashboard';
import OrderMgmt from './OrderMgmt';
import ProductMgmt from './ProductMgmt';

const LinkItems = [
  // { name: 'Dashboard', icon: FiHome },
  { name: 'Product Mgmt', icon: FiTrendingUp },
  { name: 'Order Mgmt', icon: FiCompass }
]

const SidebarContent = ({ onClose, setActiveContent, ...rest }) => {
  return (
    <Box
    transition="3s ease"
    bg={useColorModeValue('white', 'gray.900')}
    borderRight="1px"
    borderRightColor={useColorModeValue('gray.200', 'gray.700')}
    pos="fixed"
    h="full"
    {...rest}>
    <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
      <Text fontSize="xl" fontFamily="monospace" fontWeight="bold">
        Leather And Style
      </Text>
      <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
    </Flex>
    {LinkItems.map((link) => (
        <NavItem 
            key={link.name} 
            icon={link.icon} 
            onClick={() => {
            setActiveContent(link.name);
            onClose(); 
            }}
        >
        {link.name}
        </NavItem>
))}
  </Box>
  )
}

const NavItem = ({ icon, children, onClick, ...rest }) => {
    return (
      <Box
        as="a"
        href="#"
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
        onClick={onClick}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'cyan.400',
            color: 'white',
          }}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Box>
    );
  };
  
  const MobileNav = ({ onOpen, ...rest }) => {
    return (
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue('white', 'gray.900')}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
        {...rest}>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />
  
        <Text
          display={{ base: 'flex', md: 'none' }}
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold">
          Leather And Style
        </Text>
  
        <HStack spacing={{ base: '0', md: '6' }}>
          {/* <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} /> */}
          <Flex alignItems={'center'}>
            {/* <Button colorScheme='teal' variant='solid' marginRight='1rem'> 
                Logout
            </Button> */}
          </Flex>
        </HStack>
      </Flex>
    )
  }
  
  
  const AdminPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeContent, setActiveContent] = useState('Dashboard')
    const renderContent = () => {
        switch (activeContent) {
          case 'Dashboard':
            return <ProductMgmt />
          case 'Order Mgmt':
            return <OrderMgmt />
          case 'Product Mgmt':
            return <ProductMgmt />
          default:
            return <ProductMgmt />
        }
      }
  
    return (
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} setActiveContent={setActiveContent}/>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} setActiveContent={setActiveContent}/>
          </DrawerContent>
        </Drawer>
        {/* Mobile navigation */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
            {renderContent()}
        </Box>
      </Box>
    )
  }
  

export default AdminPage;
