import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Text,
  useToast,
  Center,
  InputRightElement,
  InputGroup
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import logo from '../../assets/logos/newlogonobg.png'

const PasswordRecoveryComponent = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const toast = useToast();
  const [showPassword1, setShowPassword1]= useState(false);
  const [showPassword, setShowPassword]= useState(false);

  const handlePasswordReset = async () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match, pls re-enter');
      return;
    }

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      const response = await axios.post(
        'http://leatherandstyle.netlify.app/.netlify/functions/setNewPassword',
        { token, newPassword }
      );

      if (response.status === 200) {
        toast({
          title: 'Password Reset Successful',
          description: 'Your new password has been set. Please log in with your new password.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/login');
      }
    } catch (err) {
      setError('Failed to reset password. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to reset password. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Center height="100vh" bg="gray.50" display='flex' flexDirection='column'>
        
      <Box
        p={8}
        bg="white"
        borderRadius="md"
        boxShadow="lg"
        width={['90%', '80%', '60%', '40%']} // Responsive width
      >
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'0.8rem', marginBottom: '0.8rem'}}>
            <img src={logo} alt="logo" style={{width:'2.3rem'}}/>
            <div style={{ fontFamily:'Gowun Batang', fontSize:'2rem', fontWeight:'bold', letterSpacing:'2px'}}>Leather & Style</div>
        </div>
      
        <VStack spacing={4} align="flex-start">
          <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb={4}>
            Password Recovery
          </Text>

          <FormControl isRequired>
            <FormLabel htmlFor="newPassword">New Password</FormLabel>
            <InputGroup>
            <Input
              id="newPassword"
              type={showPassword? 'text':'password'}
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              isInvalid={error !== ''}
            />
            <InputRightElement>
                <Button colorScheme='transparent' color='black'>
                    {
                        showPassword ? (
                            <ViewOffIcon onClick={ ()=> setShowPassword(false)}/>
                        ) : (
                                <ViewIcon onClick={() => setShowPassword(true)}/>
                        )
                    }
                </Button>
            </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor="confirmPassword">Confirm New Password</FormLabel>
                <InputGroup>
                    <Input
                    id="confirmPassword"
                    type={showPassword1? 'text':'password'}
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    isInvalid={error !== ''}
                    />
                    <InputRightElement>
                        <Button colorScheme='transparent' color='black'>
                            {
                                showPassword1 ? (
                                    <ViewOffIcon onClick={ ()=> setShowPassword1(false)}/>
                                ) : (
                                        <ViewIcon onClick={() => setShowPassword1(true)}/>
                                )
                            }
                        </Button>
                    </InputRightElement>
                </InputGroup>
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>

          <Button
            colorScheme="teal"
            width="full"
            onClick={handlePasswordReset}
            isLoading={false} 
          >
            Set New Password
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

export default PasswordRecoveryComponent;
